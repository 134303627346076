/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
    return (
        <>
            <div>
                {children}
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Aksepter"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics"
            >
                Denne nettsiden bruker cookies til å forbedre brukeropplevelsen.
            </CookieConsent>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
